// Generated by Framer (ced4598)

import * as React from "react";
import { motion, useOnVariantChange, useActiveVariantCallback, LayoutGroup, Image, transformTemplate, addFonts, withCSS, Text, Stack } from "framer";
import { cx } from "https://framer.com/m/framer/cx.js@^1.0.0";
import { useRandomID } from "https://framer.com/m/framer/randomID.js@^2.0.0";
import { addPropertyControls, ControlType } from "https://framer.com/m/framer/PropertyControls.js@^1.0.0";
import { useAddVariantProps } from "https://framer.com/m/framer/useAddVariantProps.js@^1.0.0";
import { useVariantState, CycleVariantState } from "https://framer.com/m/framer/useVariantState.js@^1.0.0";

const enabledGestures = {"jrxIlZvGd": {"hover": true}};

const cycleOrder = ["jrxIlZvGd"];

const variantClassNames = {"jrxIlZvGd": "framer-v-jjvm9c"};

const humanReadableVariantMap = {};

const transitions = {"default": {"type": "spring", "ease": [0.44, 0, 0.56, 1], "duration": 0.3, "delay": 0, "stiffness": 500, "damping": 60, "mass": 1}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; variant?: string; title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ style, className, layoutId, width, height, variant: outerVariant = "jrxIlZvGd", title: bXio6k7xo = "Page One", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const { variants, baseVariant, gestureVariant, classNames, transition, setVariant, setGestureState } = useVariantState({defaultVariant: "jrxIlZvGd", variant, transitions, variantClassNames, enabledGestures, cycleOrder});

const variantProps = React.useMemo(() => ({}), []);

const addVariantProps = useAddVariantProps(baseVariant, gestureVariant, variantProps);

const defaultLayoutId = useRandomID();

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} className={cx("framer-3e200", classNames)} style={{"display": "contents", "pointerEvents": "auto"}} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})}>
<Stack {...restProps} layoutId="jrxIlZvGd" className={cx("framer-jjvm9c", className)} style={{...style}} background={null} direction="horizontal" distribution="center" alignment="center" gap={10} __fromCanvasComponent={true} __contentWrapperStyle={{"width": "auto", "height": "auto", "padding": "10px 10px 10px 10px"}} center={false} data-framer-name="Variant 1" transition={transition} ref={ref} {...addVariantProps("jrxIlZvGd")}><Text style={{"opacity": 0.5, "--framer-font-family": "\"DM Sans\", sans-serif", "--framer-font-style": "normal", "--framer-font-weight": 400, "--framer-text-color": "rgb(0, 0, 0)", "--framer-font-size": "18px", "--framer-letter-spacing": "0px", "--framer-text-transform": "none", "--framer-text-decoration": "none", "--framer-line-height": "1em", "--framer-text-alignment": "center", "--framer-link-text-decoration": "underline"}} withExternalLayout={true} verticalAlignment="top" __fromCanvasComponent={true} alignment="center" fonts={["GF;DM Sans-regular"]}  layoutId="alHGRHyD7" className="framer-4o65h0" rawHTML={"<div style='font-size: 0; line-height: 0; tab-size: 4; white-space: inherit; word-wrap: inherit'><div style='direction: ltr; font-size: 0'><span style=''>Page One</span><br></div></div>"} text={bXio6k7xo} transition={transition} {...addVariantProps("alHGRHyD7")} variants={{"jrxIlZvGd-hover": {"opacity": 1}}}/></Stack>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-3e200 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", ".framer-3e200 .framer-jjvm9c { position: relative; overflow: visible; width: min-content; height: min-content; }", ".framer-3e200 .framer-4o65h0 { position: relative; overflow: visible; width: auto; height: auto; flex: none; white-space: pre; }", ".framer-3e200.framer-v-jjvm9c .framer-jjvm9c { cursor: pointer; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 38
 * @framerIntrinsicWidth 99
 * @framerCanvasComponentVariantDetails {"propertyName": "variant", "data": {"default": {"layout": ["auto", "auto"]}, "Z87m1MXDd": {"layout": ["auto", "auto"]}}}
 * @framerVariables {"bXio6k7xo": "title"}
 */
const FramerJdagoBxR4: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerJdagoBxR4;

FramerJdagoBxR4.displayName = "Item";

FramerJdagoBxR4.defaultProps = {"width": 99, "height": 38};

addPropertyControls(FramerJdagoBxR4, {"bXio6k7xo": {"type": ControlType.String, "title": "Title", "defaultValue": "Page One", "displayTextArea": false}} as any);

addFonts(FramerJdagoBxR4, [{"url": new URL("https://fonts.gstatic.com/s/dmsans/v6/rP2Hp2ywxg089UriOZSCHBeHFl0.ttf", import.meta.url).href, "family": "DM Sans", "style": "normal", "weight": "400", "moduleAsset": {"url": "https://fonts.gstatic.com/s/dmsans/v6/rP2Hp2ywxg089UriOZSCHBeHFl0.ttf", "localModuleIdentifier": "local-module:canvasComponent/JdagoBxR4:default"}}]);